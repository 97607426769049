import React from "react"
import Layout from "../component/Layout"
import "../styles/pages.modules.scss"
import "../styles/styles.scss"
import { Link } from "gatsby"
import SEO from "../component/SEO"
import Quotes from "../component/Quotes"
import Divider from "../component/Divider"
import Testimonials from "../component/Testimonials"

const index = props => {
  return (
    <Layout>
      <SEO title="Home" />
      <section id="home-content" className="hero-content home">
        <div id="home-title">
          <div id="home-inner-wrap">
            <h1>Stay at Dorlands</h1>

            <p>
              Relax Unwind and Experience The Breathtaking Views of the Usk
              Valley.
            </p>
          </div>
        </div>
      </section>

      <section className="home-page">
        <div className="slogan">
          {/* <Divider /> */}
          {/* <AvailabilityDh /> */}
          <h2>Welcome to Dorlands</h2>
          <p>
            Two large properties, a luxury shepherd's hut and a barn for the big
            events. Incredible views, acres of land, perfectly located and well
            equipped. A great place for families, large groups and big get
            togethers! Each property can be booked separately or as a whole.
          </p>
        </div>
      </section>

      <Quotes
        quote="This is a superb, spacious house with wonderful views and very good facilities. Highly recommended."
        name="-Tim"
      />
      <section id="properties" className="home-pictures-section">
        <div className="house-wrap">
          <Link className="picture pic-one" to="/dorlands-house">
            <span>Dorlands House</span>

            <span className="click">Click Here</span>
          </Link>
          <div className="liteDescriptionWrap">
            <div className="house-wrap-item-dh">
              <ul>
                <li>Sleeps 17</li>
                <li>7 Bedrooms</li>
                <li>Hot Tub</li>
                <li>Large Dining Room</li>
                <li>4 Bathrooms</li>
              </ul>
            </div>
            <div className="liteDescription">
              <h2>Dorlands House</h2>
              <p>
                This is the perfect, spacious and comfortable house for a large
                group of people to gather together.
              </p>
              <p>
                The rooms are airy and generous, and invite you to come on in,
                relax and enjoy!
              </p>
            </div>
          </div>
        </div>

        <div className="house-wrap">
          <Link className="picture pic-two" to="dorlands-country-house">
            <span>Dorlands Country House</span>

            <span className="click">Click Here</span>
          </Link>
          <div className="liteDescriptionWrap">
            <div className="house-wrap-item-dch">
              <ul>
                <li>Sleeps 16</li>
                <li>6 Bedrooms</li>
                <li>Hot Tub</li>
                <li>Wood Burner</li>
                <li>3 Bathrooms</li>
              </ul>
              {/* <AvailabilityDch /> */}
            </div>
            <div className="liteDescription">
              <h2>Dorlands Country House</h2>
              <p>
                Open the door to a warm ‘home from home’ feeling, a cosy,
                relaxed and welcoming space, with bags of style.
              </p>
              <p>
                A perfect place to bring family and friends to have fun and
                celebrate being together.
              </p>
            </div>
          </div>
        </div>
        {/* <div className="house-wrap">
          <Link className="picture pic-four" to="/weddings">
            <span>Dorlands Weddings</span>

            <span className="click">Click Here</span>
          </Link>
          <div className="liteDescriptionWrap">
            <div className="house-wrap-item-dch">
              <ul>
                <li>Private Bar</li>
                <li>Large Capacity</li>
                <li>Parties</li>
                <li>Weddings</li>
                <li>Events</li>
              </ul>
            </div>
            <div className="liteDescription">
              <h2>Dorlands Weddings</h2>
              <p>
                Dorlands is a phenomenal place to celebrate your big day with
                all your friends and family. Up to 120 guests.
              </p>
              <p>
                We will help you create an amazing day you'll never forget.
                Please feel free to contact us.
              </p>
            </div>
          </div>
        </div> */}
        <div className="house-wrap">
          <Link className="picture pic-three" to="/frankys-hide-out">
            <span>Franky's Hideout</span>

            <span className="click">Click Here</span>
          </Link>
          <div className="liteDescriptionWrap">
            <div className="house-wrap-item-dch">
              <ul>
                <li>Sleeps 2</li>
                <li>Wood Burner</li>
                <li>Pizza Oven</li>
                <li>Self Contained</li>
                <li>Gorgeous Views</li>
              </ul>
            </div>
            <div className="liteDescription">
              <h2>Franky's Hideout</h2>
              <p>
                This beautiful shepherds hut has everything. It nestles in a
                field, sheltered on two sides by woodland.
              </p>
              <p>
                It also has amazing views over the Usk Valley to the Black
                Mountains and beyond..
              </p>
            </div>
          </div>
        </div>
      </section>
      <Testimonials
        title="The Perfect Venue"
        testimonial="We want to say a huge thanks to the team at Dorlands, they all worked so hard and helped create the most amazing day. You must go and visit and see how incredible the views really are."
        name="Claire Wood"
      />
      <Quotes quote="All Properties Can Be Booked Together" name="" />
    </Layout>
  )
}

export default index
